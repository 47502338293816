import http from '../http-common';

class Stones {
  getWalls() {
    return http.get('/stones/walls');
  }
  getStones(id) {
    return http.get(`/stones/list/${id}`);
  }
  create(params) {
    return http.post('/stones/buy', params);
  }
}

export default new Stones();
