import http from '../http-common';

class SiteSettings {
  getAllSettings() {
    return http.get('/settings/site');
  }

  getAllRoutes() {
    return http.get('/router/list');
  }

  getDonatorsList(params) {
    return http.post('/donators/legacy-list', params);
  }
}

export default new SiteSettings();
