import http from '../http-common';

class Donation {
  getDonationProducts() {
    return http.get('/donation/products');
  }
  create(params) {
    return http.post('/donation/create', params);
  }
}
export default new Donation();
