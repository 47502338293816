<template lang="html">
  <div class="button" :class="buttonClasses" :style="cssVars">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    styleButton: {
      type: String,
      required: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#0062AE',
    },
  },
  data() {
    return {
      buttonClasses: {
        'button--border': this.styleButton === 'border',
        'button--radius': this.styleButton === 'radius',
      },
    };
  },
  computed: {
    cssVars() {
      return {
        '--button-background-color': this.backgroundColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
