import Donation from '@/services/Donation';
import { calculateHeightTitles } from '@/utils';

const state = {
  donationProducts: null,
  currentProduct: null,
};

// getters
const getters = {};

// actions
const actions = {
  fetchDonationProducts({ commit }) {
    Donation.getDonationProducts()
      .then(({ data }) => {
        commit('setDonationProducts', data);

        setTimeout(() => {
          calculateHeightTitles();
        });
      })
      .catch((e) => {
        console.log('Error', e);
      });
  },
};

// mutations
const mutations = {
  setDonationProducts(state, payload) {
    state.donationProducts = payload;
  },
  setCurrentProduct(state, payload) {
    state.currentProduct = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
