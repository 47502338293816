// import Donation from '@/services/Donation';

const initialState = () => ({
  currentStep: 1,
  value: 0,
  formStepsIsShow: false,
  donatedProductIsShow: false,
  steps: [
    { id: 1, name: 'Häufigkeit', passed: false },
    { id: 2, name: 'Kontaktinformation', passed: false },
    { id: 3, name: 'Zahlungsart', passed: false },
    { id: 4, name: 'Fertig', passed: false },
  ],
  regularVariants: [
    { id: 'no', label: 'Ich möchte einmalig spenden.' },
    {
      id: 'annually',
      label: 'Ich möchte jährlich spenden.',
    },
    {
      id: 'monthly',
      label: 'Ich möchte monatlich spenden.',
    },
  ],
  salutationVariants: [
    { value: '1', label: 'Herr' },
    { value: '2', label: 'Frau' },
  ],
  formData: {
    regular: 'no',
  },
  selectedCategory: null,
});

const state = initialState();

// getters
const getters = {};

// actions
const actions = {
  reset({ commit }) {
    commit('resetDonationForm');
  },
};

// mutations
const mutations = {
  setSelectedCategory(state, value) {
    state.selectedCategory = value;
  },
  setValue(state, value) {
    state.value = Number(value);
  },
  setFormStepsIsShow(state, payload) {
    state.formStepsIsShow = payload;
  },
  setFormDataRegular(state, payload) {
    state.formData.regular = payload;
  },
  setCurrentStep(state, payload) {
    state.currentStep = payload;
  },
  setPassedStep(state, index) {
    state.steps[index - 1].passed = true;
  },
  showCheckoutPage(state) {
    state.steps.forEach((step, i) => {
      state.steps[i].passed = true;
    });
    state.currentStep = 4;
    state.formStepsIsShow = true;
  },
  setDonatedProductIsShow(state, payload) {
    state.donatedProductIsShow = payload;
  },
  resetDonationForm(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
