import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store/';
import panZoom from 'vue-panzoom';
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', vh + 'px');

Promise.all([store.dispatch('global/fetchSettings')]).finally(() => {
  createApp(App).use(store).use(router).use(panZoom).mount('#app');
});
