import PagesData from '@/services/PagesData';

const state = {
  sections: [],
  errorCode: null,
};

// getters
const getters = {};

// actions
const actions = {
  fetchPageData({ commit }, slug) {
    PagesData.getPage(slug)
      .then(({ data }) => {
        if (data.page_sections) {
          commit('setSections', data.page_sections);
        } else {
          commit('setErrorCode', data.code);
        }
      })
      .catch((e) => {
        console.log('Error', e);
      });
  },
};

// mutations
const mutations = {
  setSections(state, payload) {
    state.sections = payload;
  },
  setErrorCode(state, payload) {
    state.errorCode = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
