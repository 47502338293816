<template lang="html">
  <div class="errors-messages">
    <transition-group
      appear
      name="groups"
      tag="div"
      enter-active-class="bounceInDown"
      leave-active-class="slideOutRight"
    >
      <div
        class="errors-messages__item"
        v-for="error in errors"
        :key="`${error.name}-${error.id}`"
      >
        {{ error.name }} : {{ error.value }}
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'ErrorsMessages',
  computed: {
    errors() {
      return this.$store.state.errors.errors;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
