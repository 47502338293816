import SiteSettings from '@/services/SiteSettings';

const state = {
  list: [],
  donatorsListPages: null,
  donatorsListPaged: 1,
  donatorsListLoading: false,
  existLetters: [],
  donatorsListLetters: {},
  loadedItems: 0,
  itemsCount: 0,
};

// getters
const getters = {};

// actions
const actions = {
  fetchDonatorsList({ state, commit }, params) {
    if (state.donatorsListLoading) return;
    commit('setDonatorsListLoading', true);

    SiteSettings.getDonatorsList(params)
      .then(({ data }) => {
        commit('setDonatorsList', data.results ? data.results : []);
        commit('setDonatorsListPages', data.pages);
        commit('setLoadedItems', data.loaded_items);
        commit('setItemsCount', data.items);
      })
      .catch((e) => {
        console.log('Error', e);
      })
      .finally(() => {
        commit('setDonatorsListLoading', false);
      });
  },
};

// mutations
const mutations = {
  setItemsCount(state, payload) {
    state.itemsCount = payload;
  },
  setLoadedItems(state, payload) {
    state.loadedItems = payload;
  },
  setDonatorsListPaged(state, payload) {
    state.donatorsListPaged = payload;
  },
  setDonatorsListPages(state, payload) {
    state.donatorsListPages = payload;
  },
  clearDonatorsList(state) {
    state.list = [];
    state.donatorsListPaged = 1;
    state.donatorsListPages = null;

    state.existLetters = [];
    state.donatorsListLetters = {};
  },
  setDonatorsList(state, payload) {
    payload.forEach(({ name, id }) => {
      if (name) {
        const clearText = name.replace(/Firma/g, '').trim();
        let letter = clearText[0].toUpperCase();

        if (/^[+-]?\d+(\.\d+)?$/.test(letter)) {
          letter = '0-9';
        }

        if (letter && !state.existLetters.includes(letter)) {
          state.existLetters.push(letter);
          state.donatorsListLetters[id] = letter;
        }
      }
    });

    state.list.push(...payload);
  },
  setDonatorsListLoading(state, payload) {
    state.donatorsListLoading = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
