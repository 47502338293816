export function transformComponentName(str, delimiter) {
  var regex = new RegExp(delimiter + '([a-z])', 'g');
  const componentName = str.replace(regex, function (g) {
    return g[1].toUpperCase();
  });
  const finalComponentName =
    componentName[0].toUpperCase() + componentName.slice(1);

  return finalComponentName;
}

export function replacePlaceholders(str) {
  const submitFormData = localStorage.getItem('submitFormData');
  let firstName = '',
    lastName = '',
    salutation = '';

  if (submitFormData) {
    const submitFormDataToJS = JSON.parse(submitFormData);
    firstName = submitFormDataToJS.vorname.value;
    lastName = submitFormDataToJS.nachname.value;
    salutation = submitFormDataToJS.anrede.value;
  }

  return str
    .replace('{{FIRSTNAME}}', firstName)
    .replace('{{LASTNAME}}', lastName)
    .replace('{{SALUTATION}}', salutation);
}

export function heightByMaxHeight(elements) {
  if (!elements) return;
  const cardsHeight = [];
  elements.forEach((el) => {
    cardsHeight.push(el.offsetHeight);
  });

  const maxHeight = Math.max(...cardsHeight);
  elements.forEach((el) => {
    el.style.height = `${maxHeight}px`;
  });
}

export function calculateHeightTitles() {
  const titles = document.querySelectorAll('.card-title .heading');
  const titlesContainers = document.querySelectorAll('.card-title');

  const heights = [];
  let maxHeight = null;

  if (titles) {
    titles.forEach((el) => {
      heights.push(el.offsetHeight);
    });

    maxHeight = Math.max(...heights.map((o) => o));

    titlesContainers.forEach((el) => {
      el.style.height = `${maxHeight}px`;
    });
  }
}

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  if (
    /Macintosh|Mac OS X/.test(userAgent) &&
    !/iPad|iPhone|iPod/.test(userAgent)
  ) {
    return 'macOS';
  }

  return 'unknown';
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
