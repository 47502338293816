const initialState = () => ({
  errors: [],
  removeInterval: null,
});

const state = initialState();

// mutations
const mutations = {
  setErrors(state, payload) {
    if (!Object.keys(payload).length) return;

    for (const name in payload) {
      state.errors.push({ id: Date.now(), name, value: payload[name] });
    }

    this.commit('errors/startRemoveInterval');
  },
  removeError(state, payload) {
    state.errors = payload;
  },
  clearRemoveInterval(state) {
    clearInterval(state.removeInterval);
    state.removeInterval = null;
  },
  startRemoveInterval(state) {
    if (state.removeInterval !== null) return;
    state.removeInterval = setInterval(() => {
      const removedErrors = [...state.errors];
      removedErrors.shift();
      this.commit('errors/removeError', removedErrors);
      if (!state.errors.length) {
        this.commit('errors/clearRemoveInterval');
      }
    }, 2000);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
