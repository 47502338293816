import axios from 'axios';
const rootApi = process.env.VUE_APP_ROOT_URL;

export default axios.create({
  baseURL: `${rootApi}/api/`,
  headers: {
    'Content-type': 'application/json',
  },
  auth: {
    username: 'user_rest_api',
    password: 'I4m^M0lsvzdStc1s!6i%cMJU',
  },
});
