import { createStore, createLogger } from 'vuex';
import global from '@/store/modules/global';
import page from '@/store/modules/page';
import singlePage from '@/store/modules/singlePage';
import donationProducts from '@/store/modules/donationProducts';
import donationProductForm from '@/store/modules/donationProductForm';
import donationCandles from '@/store/modules/donationCandles';
import stones from '@/store/modules/stones';
import organ from '@/store/modules/organ';
import donationPayment from '@/store/modules/donationPayment';
import errors from '@/store/modules/errors';
import preloader from '@/store/modules/preloader';
import donatorsList from '@/store/modules/donatorsList';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    global,
    page,
    singlePage,
    donationProducts,
    donationProductForm,
    donationCandles,
    stones,
    organ,
    donationPayment,
    errors,
    preloader,
    donatorsList,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
