import Candles from '@/services/Candles';

const initialState = () => ({
  list: [],
  singleCandle: null,
  createCandleFormIsShow: false,
  form: {
    steps: [
      { id: 1, name: 'Nachricht', passed: false },
      { id: 2, name: 'Beitrag', passed: false },
      { id: 3, name: 'Daten', passed: false },
      { id: 4, name: 'Zahlungsart', passed: false },
      { id: 5, name: 'Fertig', passed: false },
    ],
    currentStep: 1,
    value: 5,
  },
  formData: {
    price: '2',
  },
  previewIsShow: false,
  changeStatusIsShow: false,
  previewData: {
    candle_from: '',
    candle_for: '',
    message: '',
  },
  priceVariants: [
    // { id: '1', label: '<span>2 €</span> Licht für eine Stunde.', value: 2 },
    // { id: '2', label: '<span>5 €</span> Licht für einen Tag.', value: 5 },
    // { id: '3', label: '<span>10 €</span> Licht für eine Woche.', value: 10 },
    // { id: '4', label: '<span>25 €</span> Licht für einen Monat.', value: 25 },
    // {
    //   id: '5',
    //   label: '<span>100 €</span> Licht für ein halbes Jahr.',
    //   value: 100,
    // },
    // { id: '6', label: '<span>200 €</span> Licht für ein Jahr.', value: 200 },
  ],
  customText: 'Für Ihren Beitrag brennt Ihre Kerze für:',
});

const state = initialState();

// getters
const getters = {};

// actions
const actions = {
  reset({ commit }) {
    commit('reset');
  },
  fetchList({ commit }) {
    Candles.getAll()
      .then(({ data }) => {
        // Add First Candle for Create
        const createCandle = {
          ID: 'create-candle',
          status: 'create',
        };

        commit('setList', [createCandle, ...data]);
      })
      .catch(() => {
        // console.log('Error', e);
      });
  },
};

// mutations
const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  setSingleCandle(state, payload) {
    state.singleCandle = payload;
  },
  setPriceVariants(state, payload) {
    const prices = [];
    payload.forEach((el, i) => {
      prices.push({
        id: String(Number(i) + 1),
        label: `<span>${el.value} €</span> ${el.label}.`,
        value: Number(el.value),
        duration: el.duration,
      });
    });

    state.priceVariants = prices;
  },
  setFormDataPrice(state, payload) {
    state.formData.price = payload;

    const getValue = state.priceVariants.find(
      ({ id }) => id === state.formData.price
    );
    if (getValue) {
      state.form.value = getValue.value;
    }
  },
  setCurrentStep(state, payload) {
    state.form.currentStep = payload;
  },
  changeStepStatePassed(state, { index, payload }) {
    state.form.steps[index - 1].passed = payload;
  },
  setPassedStep(state, index) {
    state.form.steps[index - 1].passed = true;
  },
  setPreviewIsShow(state, payload) {
    state.previewIsShow = payload;
  },
  setPreviewData(state, payload) {
    state.previewData = payload;
  },
  setCustomPriceValue(state, payload) {
    state.form.value = payload;
  },
  setStatusCandle(state, payload) {
    state.singleCandle.status = payload;
  },
  setChangeStatusIsShow(state, payload) {
    state.changeStatusIsShow = payload;
  },
  setCreateCandleFormIsShow(state, payload) {
    state.createCandleFormIsShow = payload;
  },
  showCheckoutPage(state) {
    state.form.steps.forEach((step, i) => {
      state.form.steps[i].passed = true;
    });
    state.form.currentStep = 5;
    state.createCandleFormIsShow = true;
  },
  reset(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
