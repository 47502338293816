import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'home',
    // component: Home,
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Page.vue'),
  },
  // {
  //   path: '/poly',
  //   name: 'Poly',
  //   component: () => import(/* webpackChunkName: "page" */ '@/pages/Poly.vue'),
  // },
  {
    path: '/:slug',
    name: 'page',
    component: () => import(/* webpackChunkName: "page" */ '@/pages/Page.vue'),
  },
  {
    path: '/:slug/:id',
    name: 'single-page',
    component: () =>
      import(
        /* webpackChunkName: "page" */ '@/pages/SinglePage/SinglePage.vue'
      ),
  },
  {
    path: '/:pathMatch(.*)*',
    component: () =>
      import(/* webpackChunkName: "page" */ '@/pages/ErrorPage/ErrorPage.vue'),
    // beforeEnter(to, from, next) {
    //   next('/');
    // },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// process.env.BASE_URL

router.beforeEach((to, from, next) => {
  // donations
  // stones
  // candles
  if (to.query.type === 'donations' && to.query.status === 'success') {
    next(`/${store.state.global.slugs.donationShop}`);
    store.commit('donationProductForm/showCheckoutPage');
    store.commit('donationProductForm/setValue', to.query.amount);
    store.commit('donationPayment/setPostId', to.query.additional_id);
    // ?type=donations&status=success&donated_post_id=422&amount=50
  } else if (to.query.type === 'candles' && to.query.status === 'success') {
    next(`/${store.state.global.slugs.candles}`);
    store.commit('donationCandles/showCheckoutPage');
    store.commit('donationPayment/setPostId', to.query.donated_post_id);
    //?type=candles&status=success&donated_post_id=6412&amount=10
  } else if (to.query.type === 'stones' && to.query.status === 'success') {
    next(`/${store.state.global.slugs.stones}`);
    store.commit('stones/showCheckoutPage');
    store.commit('donationPayment/setPostId', to.query.donated_post_id);
    //?type=candles&status=success&donated_post_id=386&amount=10
  } else if (to.query.type === 'pipes' && to.query.status === 'success') {
    next(`/${store.state.global.slugs.organ}`);
    store.commit('organ/showCheckoutPage');
    store.commit('donationPayment/setPostId', to.query.donated_post_id);
    //?type=pipes&status=success&donated_post_id=10274&amount=2000#fbForm
  } else {
    next();
  }
});

export default router;
