<template>
  <div class="page-404">
    <img
      class="page-404__picture"
      src="@/assets/images/building2.png"
      alt="Building"
    />
    <div class="page-404__content">
      <h1 class="page-404__title">{{ errorData.title }}</h1>
      <div class="page-404__subtitle">{{ errorData.subtitle }}</div>
      <div class="page-404__button" v-if="errorData.button">
        <Button>
          <router-link :to="errorData.button.url">
            {{ errorData.button.title }}
          </router-link>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button';
export default {
  name: 'ErrorPage',
  components: { Button },
  props: {
    errorType: {
      type: String,
      required: true,
    },
  },
  computed: {
    errorData() {
      return this.$store.state.global.settings.error_404;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
