import http from '../http-common';

class Candles {
  getAll() {
    return http.get('/candle/list');
  }
  getItem(id) {
    return http.get(`/candle/${id}`);
  }
  create(params) {
    return http.post('/candle/create', params);
  }
}

export default new Candles();
