import Candles from '@/services/Candles';

const initialState = () => ({
  singlePageData: {},
  errorCode: null,
});

const state = initialState();

// getters
const getters = {};

// actions
const actions = {
  fetchSinglePageData({ commit }, { postId, postType }) {
    if (postType === 'candles') {
      Candles.getItem(postId)
        .then(({ data }) => {
          if (data) {
            commit('setSinglePageData', data);
          } else {
            commit('setErrorCode', data.code);
          }
        })
        .catch((e) => {
          console.log('Error', e);
        });
    }
  },
  reset({ commit }) {
    commit('reset');
  },
};

// mutations
const mutations = {
  setSinglePageData(state, payload) {
    state.singlePageData = payload;
  },
  setErrorCode(state, payload) {
    state.errorCode = payload;
  },
  reset(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
