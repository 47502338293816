import Donation from '@/services/Donation';
import Candles from '@/services/Candles';
import Stones from '@/services/Stones';
import Pipes from '@/services/Pipes';
import { loadStripe } from '@stripe/stripe-js';

const initialState = () => ({
  paymentMethod: 'paypal',
  postType: null,
  postId: null,
  donationPostId: null,
  amount: null,
  formData: {},
  loaded: true,
  stripe: null,
  googleApplePayData: {},
  paymentRequest: null,
  stripeCardData: {
    cvv: '',
    number: '',
    expiry: '',
  },
  creditCardData: {
    bankAccountOwner: '',
    bankIban: '',
    // bankBic: '',
  },
});

const state = initialState();

// getters
const getters = {
  isStripe(state) {
    return state.paymentMethod === 'stripe_credit_card';
  },
  isSepa(state) {
    return state.paymentMethod === 'wikando_direct_debit';
  },
  sepaFormIsEmpty(state, getters) {
    return (
      getters.isSepa &&
      (!state.creditCardData.bankAccountOwner.trim() ||
        !state.creditCardData.bankIban.trim())
      // || !state.creditCardData.bankBic.trim()
    );
  },
  stripeFormIsEmpty(state, getters) {
    return (
      getters.isStripe &&
      (!state.creditCardData.bankAccountOwner.trim() ||
        !state.stripeCardData.cvv.trim() ||
        !state.stripeCardData.number.trim() ||
        !state.stripeCardData.expiry.trim() ||
        state.stripeCardData.expiry.length < 5)
    );
  },
};

// actions
const actions = {
  pay({ rootState, state, commit, dispatch, getters }, payload) {
    if (getters.sepaFormIsEmpty || getters.stripeFormIsEmpty) return;

    if ('payment_intent' in state.googleApplePayData) {
      dispatch('startAppleGooglePay');

      return;
    }

    if (!state.loaded) return;
    commit('setLoaded', false);

    const { postType } = payload;

    let expiry = '';
    if (state.stripeCardData.expiry) {
      expiry = state.stripeCardData.expiry.replace('/', '/20');
    }

    const params = {
      donator: {
        salutation: state.formData.anrede.value,
        first_name: state.formData.vorname.value,
        last_name: state.formData.nachname.value,
        email: state.formData.email.value,
        ip: rootState.global.settings.user_ip,

        ...(state.formData.telefonnummer && {
          phone: state.formData.telefonnummer.value,
        }),

        ...(localStorage.getItem('donationReceipt') === 'yes' && {
          donation_receipt: 'yes',
          street: state.formData.strabe.value,
          number: state.formData.nr.value,
          zip_code: state.formData.plz.value,
          // country: ....
        }),

        ...(localStorage.getItem('donationCompanyName') === 'yes' && {
          donate_company: 'yes',
          company_name: state.formData.firmenname.value,
        }),

        // TODO : NEW FIELDS
        country: state.formData.land.value, // country: state.formData.land.value,
        city: state.formData.city.value,

        amount: ['candles', 'stones', 'organ'].includes(postType)
          ? payload.candle.amount
          : payload.donation.donation_amount,
        recurring_payments: ['candles', 'stones', 'organ'].includes(postType)
          ? 'no'
          : payload.donation.recurring_payments,
        payment_method: state.paymentMethod, // "direct_debit" or "paypal",
        ...(getters.isSepa && {
          bank_account_owner: state.creditCardData.bankAccountOwner,
          bank_iban: state.creditCardData.bankIban,
          // bank_bic: state.creditCardData.bankBic,
        }),

        ...(getters.isStripe && {
          credit_card_owner: state.creditCardData.bankAccountOwner,
          credit_card_number: state.stripeCardData.number,
          credit_card_secure_id: state.stripeCardData.cvv,
          credit_card_expiry: expiry,
        }),
      },
    };

    commit('setPostType', postType);

    if (postType === 'donations') {
      params.donation = payload.donation;
      dispatch('donationProductPay', params);
    } else if (postType === 'candles') {
      params.candle = payload.candle;
      dispatch('candlePay', params);
    } else if (postType === 'stones') {
      params.stone_id = payload.stone_id;
      params.paid_price = payload.paid_price;

      params.stone = {
        // stone_from: rootState.stones.previewData.candle_from,
        // stone_for: rootState.stones.previewData.candle_for,
        message: rootState.stones.previewData.message,
      };

      dispatch('stonePay', params);
    } else if (postType === 'organ') {
      params.pipe_id = payload.pipe_id;
      params.paid_price = payload.paid_price;
      params.pipe = {
        message: rootState.organ.previewData.message,
      };
      dispatch('pipePay', params);
    }
  },
  donationProductPay({ dispatch, commit }, payload) {
    // let isSuccess = false;
    commit('preloader/show', null, { root: true });
    Donation.create(payload)
      .then(({ data }) => {
        commit('setPostId', data.post_id);
        commit('setDonationPostId', data.post_id);

        if ('payment_intent' in data) {
          commit('setGoogleApplePayData', data);
          dispatch('prepareAppleGooglePay');
        } else {
          window.location.href = data.redirect_url;
        }
        // isSuccess = true;
      })
      .catch((e) => {
        // console.log('Error', e);
        let errors = {};

        for (const errorsKey in e.response.data.errors) {
          errors = { ...errors, ...e.response.data.errors[errorsKey] };
        }

        commit('errors/setErrors', errors, { root: true });
      })
      .finally(() => {
        commit('setLoaded', true);
        commit('preloader/hide', null, { root: true });
      });
  },
  candlePay({ commit, rootState, dispatch }, payload) {
    // let isSuccess = false;
    commit('preloader/show', null, { root: true });
    Candles.create(payload)
      .then(({ data }) => {
        commit('setPostId', data.post_id);
        commit('setDonationPostId', data.post_id);

        if ('payment_intent' in data) {
          commit('setGoogleApplePayData', data);
          dispatch('prepareAppleGooglePay');
        } else {
          window.location.href = data.redirect_url;
        }

        // isSuccess = true;
        localStorage.setItem(
          'candlePreviewData',
          JSON.stringify(rootState.donationCandles.previewData)
        );
      })
      .catch((e) => {
        // console.log('Error', e);
        let errors = {};

        for (const errorsKey in e.response.data.errors) {
          errors = { ...errors, ...e.response.data.errors[errorsKey] };
        }

        commit('errors/setErrors', errors, { root: true });
      })
      .finally(() => {
        commit('setLoaded', true);
        commit('preloader/hide', null, { root: true });
      });
  },
  stonePay({ commit, dispatch }, payload) {
    // let isSuccess = false;
    commit('preloader/show', null, { root: true });
    Stones.create(payload)
      .then(({ data }) => {
        commit('setPostId', data.post_id);
        commit('setDonationPostId', data.post_id);

        if ('payment_intent' in data) {
          commit('setGoogleApplePayData', data);
          dispatch('prepareAppleGooglePay');
        } else {
          window.location.href = data.redirect_url;
        }
        // isSuccess = true;
        commit('stones/setFormCurrentStep', 3);
      })
      .catch((e) => {
        // console.log('Error', e);
        let errors = {};

        for (const errorsKey in e.response.data.errors) {
          errors = { ...errors, ...e.response.data.errors[errorsKey] };
        }

        commit('errors/setErrors', errors, { root: true });
      })
      .finally(() => {
        commit('setLoaded', true);
        commit('preloader/hide', null, { root: true });
      });
  },
  pipePay({ commit, dispatch }, payload) {
    // let isSuccess = false;
    commit('preloader/show', null, { root: true });
    Pipes.create(payload)
      .then(({ data }) => {
        commit('setPostId', data.post_id);
        commit('setDonationPostId', data.post_id);
        if ('payment_intent' in data) {
          commit('setGoogleApplePayData', data);
          dispatch('prepareAppleGooglePay');
        } else {
          window.location.href = data.redirect_url;
        }
        // isSuccess = true;
      })
      .catch((e) => {
        // console.log('Error', e);
        let errors = {};

        for (const errorsKey in e.response.data.errors) {
          errors = { ...errors, ...e.response.data.errors[errorsKey] };
        }

        commit('errors/setErrors', errors, { root: true });
      })
      .finally(() => {
        commit('setLoaded', true);
        commit('preloader/hide', null, { root: true });
      });
  },
  async prepareAppleGooglePay({ state, commit }) {
    try {
      const stripe = await loadStripe(
        state.googleApplePayData.payment_intent.publishable_key,
        {
          stripeAccount: state.googleApplePayData.payment_intent.stripe_account,
        }
      );
      commit('setStripe', stripe);

      const properties = {
        country: state.formData.land.value,
        currency: state.googleApplePayData.payment_intent.currency,
        total: {
          label: state.googleApplePayData.payment_intent.orga_name,
          amount: state.googleApplePayData.payment_intent.amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      };

      const paymentRequest = stripe.paymentRequest(properties);
      console.log(paymentRequest);
      commit('setPaymentRequest', paymentRequest);

      const paymentType = {};
      if (state.paymentMethod === 'stripe_apple_pay') {
        paymentType.applePay = true;
      } else if (state.paymentMethod === 'stripe_google_pay') {
        paymentType.googlePay = true;
      }

      const canMakePayment = await paymentRequest.canMakePayment();

      if (!canMakePayment) {
        alert(
          `${
            paymentType.applePay ? 'Apple Pay' : 'Google Pay'
          } is not supported on this device or browser`
        );
        return;
      }
    } catch (error) {
      console.error('Error preparing payment data:', error);
      alert('Error preparing payment data');
    }
  },
  startAppleGooglePay({ state }) {
    if (!state.paymentRequest) {
      alert('The payment request was not prepared.');
      return;
    }

    state.paymentRequest.on('paymentmethod', async (ev) => {
      // try {
      //   const { error } = await state.stripe.confirmCardPayment(
      //     state.googleApplePayData.client_secret,
      //     {
      //       payment_method: ev.paymentMethod.id,
      //     }
      //   );
      //
      //   if (error) {
      //     ev.complete('fail');
      //     alert('Payment failed!');
      //     console.error('Payment failed:', error);
      //   } else {
      //     ev.complete('success');
      //     window.location.href = state.googleApplePayData.redirect_url;
      //     console.log('Payment successful');
      //   }
      // } catch (error) {
      //   ev.complete('fail');
      //   console.error('Payment confirmation error:', error);
      //   alert('Payment confirmation error.');
      // }
      const { error } = await state.stripe.confirmCardPayment(
        state.googleApplePayData.payment_intent.client_secret,
        {
          payment_method: ev.paymentMethod.id,
        }
      );

      if (error) {
        ev.complete('fail');
        alert('Payment failed!');
        console.error('Payment failed:', error);
      } else {
        ev.complete('success');
        window.location.href = state.googleApplePayData.redirect_url;
        console.log('Payment successful');
      }
    });

    state.paymentRequest.show();
  },
};

// mutations
const mutations = {
  setStripe(state, payload) {
    state.stripe = payload;
  },
  setPaymentRequest(state, payload) {
    state.paymentRequest = payload;
  },
  setGoogleApplePayData(state, payload) {
    state.googleApplePayData = payload;
  },
  setPaymentMethod(state, payload) {
    state.paymentMethod = payload;
  },
  setPostId(state, payload) {
    state.postId = payload;
  },
  setDonationPostId(state, payload) {
    state.donationPostId = payload;
  },
  setAmount(state, payload) {
    state.amount = payload;
  },
  setFormData(state, payload) {
    state.formData = payload;
  },
  setBankAccountOwner(state, payload) {
    state.creditCardData.bankAccountOwner = payload;
  },
  setBankIban(state, payload) {
    state.creditCardData.bankIban = payload;
  },
  // setBankBic(state, payload) {
  //   state.creditCardData.bankBic = payload;
  // },
  setPostType(state, payload) {
    state.postType = payload;
  },
  setLoaded(state, payload) {
    state.loaded = payload;
  },
  reset(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setStripeCardCvv(state, payload) {
    state.stripeCardData.cvv = payload;
  },
  setStripeCardNumber(state, payload) {
    state.stripeCardData.number = payload;
  },
  setStripeCardExpiry(state, payload) {
    state.stripeCardData.expiry = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
