import SiteSettings from '@/services/SiteSettings';

const state = {
  keyboardUniqID: null,
  settings: null,
  isMobile: false,
  candlePreview: null,
  inHouse: localStorage.getItem('inHouse') || false,
  inHouseIntroScreen: true,
  slugs: {
    candles: 'kerzen-anzuenden',
    donationShop: 'spendenshop',
    stoneSponsorship: 'steinpatenschaft',
    stones: 'steine',
    organ: 'orgel',
  },
  // listRoutes: [],
};

// getters
const getters = {};

// actions
const actions = {
  fetchSettings({ commit }) {
    SiteSettings.getAllSettings()
      .then(({ data }) => {
        commit('setSettings', data);

        commit(
          'donationCandles/setPriceVariants',
          data.candles_options_prices,
          { root: true }
        );
      })
      .catch((e) => {
        console.log('Error', e);
      });
  },
  // fetchRoutes({ commit }) {
  //   SiteSettings.getAllRoutes()
  //     .then(({ data }) => {
  //       commit('setListRoutes', data);
  //     })
  //     .catch((e) => {
  //       console.log('Error', e);
  //     });
  // },
};

// mutations
const mutations = {
  setInHouseIntroScreen(state, payload) {
    state.inHouseIntroScreen = payload;
  },
  setInHouse(state, payload) {
    state.inHouse = payload;
  },
  changeInputFocus(state, payload) {
    state.keyboardUniqID = payload;
  },
  setIsMobile(state, payload) {
    state.isMobile = payload;
  },
  setSettings(state, payload) {
    state.settings = payload;
  },
  setCandlePreview(state, payload) {
    state.candlePreview = payload;
  },
  // setListRoutes(state, payload) {
  //   state.listRoutes = payload;
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
