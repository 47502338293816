import Stones from '@/services/Stones';

const initialState = () => ({
  walls: [],
  selectedArea: null,
  selectedStone: null,
  selectedWallSlug: '',
  currentStep: 1,
  steps: [
    // { id: 1, name: 'Bereich', passed: false },
    { id: 1, name: 'Gemäuer', passed: false },
    { id: 2, name: 'Stein', passed: false },
  ],
  form: {
    currentStep: 1,
    value: 30,
    formStepsIsShow: false,
    steps: [
      // { id: 1, name: 'Nachricht', passed: false },
      { id: 1, name: 'Daten', passed: false },
      { id: 2, name: 'Zahlungsart', passed: false },
      { id: 3, name: 'Bestätigung', passed: false },
    ],
  },
  stones: {
    areas: [],
    width: '',
    height: '',
    img: '',
  },
  previewData: {
    candle_from: '',
    candle_for: '',
    message: '',
  },
});

const state = initialState();

// getters
const getters = {
  getWalls: (state) => {
    const area = state.walls.find(({ name }) => name === state.selectedArea);
    let walls = [];
    if (area) {
      walls = area['sub_category'];
    }
    return walls;
  },
  getWall: (state) => (id) => {
    return state.walls.find((wall) => String(wall.ID) === String(id));
  },
};

// actions
const actions = {
  reset({ commit }) {
    commit('reset');
  },
  fetchWalls({ commit }) {
    Stones.getWalls()
      .then(({ data }) => {
        commit('setWalls', data);
      })
      .catch((e) => {
        console.log('Error', e);
      });
  },
  fetchStones({ commit }, wallId) {
    Stones.getStones(wallId)
      .then(({ data }) => {
        commit('setStones', data);
      })
      .catch((e) => {
        console.log('Error', e);
      });
  },
};

// mutations
const mutations = {
  setPreviewData(state, payload) {
    state.previewData = payload;
  },
  setWalls(state, payload) {
    state.walls = payload;
  },
  setStones(state, payload) {
    state.stones.areas = payload.filter(
      ({ status }) => status.value === 'available'
    );
  },
  reset(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setCurrentStep(state, payload) {
    state.steps[payload - 1].passed = payload > state.currentStep;
    state.currentStep = payload;
  },
  setSelectedArea(state, payload) {
    state.selectedWallSlug = payload.slug;
    state.selectedArea = payload.ID;
    state.stones.width = payload.image_width;
    state.stones.height = payload.image_height;
    state.stones.img = payload.image.url;
  },
  setSelectedStone(state, payload) {
    state.selectedStone = payload;
  },
  setFormStepsIsShow(state, payload) {
    state.form.formStepsIsShow = payload;
  },
  setFormCurrentStep(state, payload) {
    state.form.steps[payload - 1].passed = true;
    state.form.currentStep = payload;
  },
  showCheckoutPage(state) {
    state.form.steps.forEach((step, i) => {
      state.form.steps[i].passed = true;
    });
    state.form.currentStep = 3;
    state.form.formStepsIsShow = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
