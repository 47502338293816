<template lang="html">
  <div class="header__menu">
    <div
      class="header__menu-links"
      v-if="$store.state.global.settings && $store.state.global.settings.menu"
    >
      <div
        class="header__menu-link"
        v-for="(itemLink, index) in $store.state.global.settings.menu"
        :key="index"
      >
        <router-link :to="itemLink.link.url" v-if="itemLink.link">
          <span class="header__menu-link-icon" v-if="itemLink.icon">
            <img :src="itemLink.icon.url" :alt="itemLink.icon.alt" />
          </span>
          {{ itemLink.link.title }}
        </router-link>
      </div>
    </div>

    <div
      class="header__menu-bottom-links"
      v-if="
        $store.state.global.settings && $store.state.global.settings.menu_bottom
      "
    >
      <div
        class="header__menu-bottom-link"
        v-for="(itemLink, index) in $store.state.global.settings.menu_bottom"
        :key="index"
      >
        <router-link :to="itemLink.link.url" v-if="itemLink.link">
          {{ itemLink.link.title }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderMenu',
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
