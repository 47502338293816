<template lang="html">
  <div class="header">
    <div class="header__content">
      <div class="container">
        <div class="header__humburger">
          <div
            @click="switchHamburger"
            class="hamburger hamburger--collapse"
            :class="{ 'is-active': hamburgerActive }"
            v-click-outside="vcoConfig"
          >
            <div class="hamburger-box">
              <div class="hamburger-inner"></div>
            </div>
          </div>
        </div>
        <a
          v-if="
            $store.state.global.settings && $store.state.global.settings.logo
          "
          href="/"
          class="header__logo"
        >
          <img :src="$store.state.global.settings.logo.url" alt="" />
        </a>
      </div>
    </div>
    <transition name="fade">
      <HeaderMenu v-if="hamburgerActive" />
    </transition>
  </div>
</template>

<script>
import HeaderMenu from '@/components/Header/HeaderMenu.vue';
import vClickOutside from 'click-outside-vue3';
export default {
  name: 'Header',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    HeaderMenu,
  },
  data() {
    return {
      hamburgerActive: false,
      vcoConfig: {
        handler: this.onClickOutside,
        events: ['touchend', 'click'],
      },
    };
  },
  methods: {
    switchHamburger() {
      this.hamburgerActive = !this.hamburgerActive;
    },
    onClickOutside() {
      this.hamburgerActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
