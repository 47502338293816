<template lang="html">
  <div v-if="$store.state.global.settings" class="app-container">
    <Header />
    <div
      class="app-container__content"
      :class="{
        'app-container__content--white': blueWhite || error === '404',
      }"
    >
      <!--      <DesktopDummy v-if="!isMobile" />-->
      <RotateScreen v-if="isHorizontal" />
      <ErrorPage v-if="error === '404'" :errorType="error" />
      <router-view v-else></router-view>
    </div>
    <ErrorsMessages />
    <Preloader v-if="$store.state.preloader.isShow" />
    <!--    v-if="this.$store.state.errors.errors.length"-->
    <!--    <img-->
    <!--      v-if="-->
    <!--        !$store.state.global.isMobile &&-->
    <!--        $route.params.slug !== $store.state.global.slugs.candles-->
    <!--      "-->
    <!--      class="background-right-image"-->
    <!--      src="@/assets/images/building3.png"-->
    <!--      alt="Building"-->
    <!--    />-->
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue';
import ErrorPage from '@/pages/ErrorPage/ErrorPage.vue';
// import DesktopDummy from '@/components/DesktopDummy/DesktopDummy.vue';
import RotateScreen from '@/components/RotateScreen/RotateScreen.vue';
import ErrorsMessages from '@/components/ErrorsMessages/ErrorsMessages';
import Preloader from '@/components/Preloader/Preloader';

export default {
  name: 'App',
  components: {
    Preloader,
    ErrorsMessages,
    Header,
    ErrorPage,
    RotateScreen,
    // DesktopDummy,
  },
  watch: {
    $route(val) {
      this.$store.commit('page/setErrorCode', null);
      const slug = val.params.slug;

      this.$store.commit('global/setInHouseIntroScreen', true);
      if (slug === 'generate-local-storage') {
        localStorage.setItem('inHouse', true);
        this.$store.commit('global/setInHouse', true);
        this.$router.push('/');
      }

      if (!this.$store.state.global.inHouse) {
        if (['spendenregister', 'donators-list'].includes(slug)) {
          this.$router.push('/');
        }
      }
    },
  },
  data() {
    return {
      isHorizontal: false,
      // placeholderForKeyboard: '',
    };
  },
  computed: {
    error() {
      const errorCode = this.$store.state.page.errorCode;

      if (errorCode === 'rest_no_page') {
        return '404';
      } else {
        return '';
      }
    },
    blueWhite() {
      return this.$route.name === 'home';
    },
  },
  created() {
    // Check Screen Size
    this.handleIsMobile();
    window.addEventListener('resize', this.handleIsMobile);

    // Check Orientation
    this.handleOrientation();
    window.addEventListener('orientationchange', this.handleOrientation);
  },
  mounted() {
    document.addEventListener('click', this.blockClicksByExternalLinks);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleIsMobile);
    window.removeEventListener('orientationchange', this.handleOrientation);
    document.removeEventListener('click', this.blockClicksByExternalLinks);
  },
  methods: {
    blockClicksByExternalLinks(event) {
      const link = event.target.closest('a');

      if (link && link.tagName === 'A') {
        const isExternal = link.host !== window.location.host;

        if (isExternal) {
          event.preventDefault();
          console.log('External link click blocked:', link.href);
        }
      }
    },
    handleIsMobile() {
      // this.$store.commit('global/setIsMobile', window.innerWidth < 768);
      this.$store.commit('global/setIsMobile', this.isMobile());

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    handleOrientation() {
      // this.isHorizontal =
      //   (this.$store.state.global.isMobile && screen.orientation.angle !== 0) ||
      //   false;
      this.isHorizontal =
        (this.$store.state.global.isMobile && window.orientation !== 0) ||
        false;
    },
    isMobile() {
      // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      //   navigator.userAgent
      // )
      //   ? true
      //   : false;

      return document.body.clientWidth > 768 ? false : true;
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/reset';
@import '~@/styles/common';
</style>

<style lang="scss" scoped>
@import '~@/styles/variables';
@media (min-width: $largeMobile) {
  .background-right-image {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    width: auto;
  }

  :deep(.candle) {
    cursor: pointer;
  }

  :deep(.donation-form__background-picture),
  :deep(.form__background-picture) {
    display: none !important;
  }

  :deep(.donated-product) {
    max-width: 500px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
</style>
