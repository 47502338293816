import http from '../http-common';

class Pipes {
  getPipes() {
    return http.get('/pipes/list');
  }
  getGroups() {
    return http.get(`/pipes/groups`);
  }
  create(params) {
    return http.post('/pipes/buy', params);
  }
}

export default new Pipes();
