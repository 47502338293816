<template lang="html">
  <div class="preloader">
    <div class="loadingio-spinner-rolling-nteolvq23e">
      <div class="ldio-b7p8n9bmd2l">
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preloader',
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
