import Pipes from '@/services/Pipes';

const initialState = () => ({
  pipeVariantsScreenIsShown: false,
  image: null,
  // pipesList: null,
  // pipesGroups: null,
  pipeDonations: [
    // {
    //   id: 1,
    //   label: 'ab 300 € - 1.000 €',
    //   range: [300, 1000],
    // },
    // {
    //   id: 2,
    //   label: '1.000 € - 10.000 €',
    //   range: [1001, 10000],
    // },
    // {
    //   id: 3,
    //   label: '10.000 € - 17.000 €',
    //   range: [10001, 17000],
    // },
    // {
    //   id: 4,
    //   label: 'ab 17.000 €',
    //   range: [17000, 0],
    // },
  ],
  pipeVariants: null,
  selectedDonation: 2,
  selectedPipe: 1,
  currentStep: 1,
  steps: [
    // { id: 1, name: 'Bereich', passed: false },
    { id: 1, name: 'Pfeife', passed: false },
    { id: 2, name: 'Checkout', passed: false },
  ],
  form: {
    currentStep: 1,
    value: 30,
    formStepsIsShow: false,
    steps: [
      // { id: 1, name: 'Nachricht', passed: false },
      { id: 1, name: 'Daten', passed: false },
      { id: 2, name: 'Zahlungsart', passed: false },
      { id: 3, name: 'Bestätigung', passed: false },
    ],
  },
  previewData: {
    candle_from: '',
    candle_for: '',
    message: '',
  },
});

const state = initialState();

// getters
const getters = {
  currentPipeVariants: (state) => {
    return {
      areas: state.pipeVariants
        ? state.pipeVariants[state.selectedDonation].map(({ area }) => ({
            label: area,
            value: area,
          }))
        : [],
      pipes: state.pipeVariants
        ? state.pipeVariants[state.selectedDonation].map(
            ({ pipe, pipe_id }) => ({
              label: pipe,
              value: pipe_id,
            })
          )
        : [],
      prices: state.pipeVariants
        ? state.pipeVariants[state.selectedDonation].map(({ price }) => price)
        : [],
      images: state.pipeVariants
        ? state.pipeVariants[state.selectedDonation].map(({ image }) => image)
        : [],
      pipes_images: state.pipeVariants
        ? state.pipeVariants[state.selectedDonation].map(
            ({ pipe_image }) => pipe_image
          )
        : [],
    };
  },
};

// actions
const actions = {
  reset({ commit }) {
    commit('reset');
  },
  fetchPipes({ commit }) {
    Promise.all([Pipes.getPipes(), Pipes.getGroups()]).then(
      async ([pipes, groups]) => {
        commit('setPipesGroups', { pipes: pipes.data, groups: groups.data });
      }
    );
  },
};

// mutations
const mutations = {
  setPipeDonations(state, payload) {
    state.pipeDonations = payload.map((el, i) => {
      return {
        id: i + 1,
        label: el.label,
        range: [el.min, el.max],
      };
    });
  },
  setPipesGroups(state, { pipes, groups }) {
    state.pipeVariants = {};

    if (!pipes || !groups) return;

    // Filter Pipes
    for (const k in state.pipeDonations) {
      const pipesByGroups = {};

      const key = state.pipeDonations[k].id;
      const range = state.pipeDonations[k].range;

      const filteredPipes = pipes.filter((el) => {
        if (!range[1]) {
          return Number(el.price) >= Number(range[0]);
        } else {
          return (
            Number(el.price) >= Number(range[0]) &&
            Number(el.price) <= Number(range[1])
          );
        }
      });

      // Group Pipes
      filteredPipes.forEach((pipe) => {
        if (!pipesByGroups[pipe.group_id]) {
          pipesByGroups[pipe.group_id] = [pipe];
        } else {
          pipesByGroups[pipe.group_id].push(pipe);
        }
      });

      // Get 5 Pipes
      const selectedPipes = [];

      for (const groupId in pipesByGroups) {
        const group = pipesByGroups[groupId];
        const randomPipe = group.splice(
          Math.floor(Math.random() * group.length),
          1
        );

        selectedPipes.push(randomPipe[0]);
      }

      // Map Pipes
      state.pipeVariants[key] = selectedPipes.map((pipe) => {
        const findGroup = groups.find((group) => group.ID === pipe.group_id);

        return {
          area: findGroup ? findGroup.name : '',
          pipe: pipe.title,
          pipe_id: pipe.ID,
          price: Number(pipe.price),
          image: findGroup ? findGroup.image : {},
          pipe_image: pipe.image ? pipe.image : {},
        };
      });
    }
  },
  setImage(state, payload) {
    state.image = payload;
  },
  setSelectedPipe(state, payload) {
    state.selectedPipe = payload;
  },
  setSelectedDonation(state, payload) {
    state.selectedDonation = payload;
  },
  showPipeVariantsScreen(state, payload) {
    state.pipeVariantsScreenIsShown = payload;
  },
  setPreviewData(state, payload) {
    state.previewData = payload;
  },
  reset(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setCurrentStep(state, payload) {
    state.steps[payload - 1].passed = payload > state.currentStep;
    state.currentStep = payload;
  },
  setFormStepsIsShow(state, payload) {
    state.form.formStepsIsShow = payload;
  },
  setFormCurrentStep(state, payload) {
    state.form.steps[payload - 1].passed = true;
    state.form.currentStep = payload;
  },
  showCheckoutPage(state) {
    state.form.steps.forEach((step, i) => {
      state.form.steps[i].passed = true;
    });
    state.pipeVariantsScreenIsShown = true;
    state.form.currentStep = 3;
    state.form.formStepsIsShow = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
